import React from "react"
import { Link } from "gatsby"
import SEO from '../../../components/seo'

import Elements from '../../../layouts/elements'


const youso=	0
const part=		1
const title=	'５つの手順'
const url=		'318939273'

const iti=		youso + '-' +part
const h1=		'【' + iti + '】' + title
const mae= 		'/elements/'+youso+'/'+youso +'-'+(part-1)+'/'
const ato= 		'/elements/'+youso+'/'+youso +'-'+(part+1)+'/'





export default () => (


<Elements
	mae='/elements/start/'
	ato={ato}
	url={url}
	h1={h1}
>

<SEO title={title}/>



</Elements>
)
